<template>
  <div class="vip">
    <div class="vip-header"></div>
    <div
      class="vip-body"
      :style="{
        width: '100%',
        height: height + 'px',
        background: '#F3F3F3',
        padding: '34px 27px 0px 27px',
      }"
    >
      <div class="vip-statistics">
        <span
          >会员总数：<span class="red-num">{{ topData.cust_num_total }}</span
          >个；收入合计： <span class="red-num">{{ topData.amt_add_sum }}</span
          >万元；<span
            >支出合计：<span class="red-num">{{ topData.amt_sub_sum }}</span
            >万元；</span
          ><span
            >余额合计：<span class="red-num">{{ topData.amt_end_sum }}</span
            >万元；<span
              >客单价：<span class="red-num">{{ topData.cust_unit_price }}</span
              >{{ company }}</span
            ></span
          ></span
        >
      </div>
      <div
        :style="{
          width: '97%',
          height: heightSmall + 'px',
          backgroundColor: '#fff',
          marginTop: '29px'
        }"
      >
        <div class="vip-condition">
          <div class="vip-all">
            <span style="display: inline-block; width: 106px"></span>
            <el-button class="vip-buy" @click="dianji_xinzeng()">充值</el-button>
            <span style="display: inline-block; width: 60px"></span>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              format="yyyy-MM-dd"
              range-separator="至"
              value-format="yyyy-MM-dd"
              @change="query_mc_trans_list_gather()"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <h1>会员充值汇总账页</h1>
          <div class="vip-right">
            <el-button class="lookDetail" @click="lookDetail"
              >查看明细</el-button
            >
            <span style="display: inline-block; width: 30px"></span>
            <el-button class="vip-exprot" @click="handeDownload()"
              >导出</el-button
            >
            <span style="display: inline-block; width: 50px"></span>
            <el-autocomplete
              class="inputOp"
              v-model="searchVal"
              :fetch-suggestions="querySearch"
              placeholder="搜索客户名字或手机号"
              size="smallr"
              @select="handleSelect"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i
            ></el-autocomplete>
            <!-- <el-input
              class="inputOp"
              placeholder="搜索客户名字或手机号"
              size="smallr"
              v-model="searchVal">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input> -->
            <span style="display: inline-block; width: 20px"></span>
            <span class="vip-yuan">单位：元</span>
          </div>
        </div>
        <!-- handleindexChange -->
        <div class="vip-table">
          <el-table
            height="660"
            :loading="loading"
            :data="tablCeData"
            ref="table"
            @select="selectRow"
            stripe
            @selection-change="handleSelectionChange"
            :header-cell-style="{ height: '80px', background: '#F9F9F9;' }"
            @row-click="handleNeedClick"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column
              label="序号"
              align="center"
              type="index"
            ></el-table-column>
            <el-table-column
              label="客户"
              align="center"
              prop="cust_name"
            ></el-table-column>
            <el-table-column
              label="手机号"
              align="center"
              prop="mobile"
            ></el-table-column>
            <el-table-column
              label="期初余额"
              align="center"
              sortable
              prop="amt_bgn"
            >
              <template slot-scope="scope">
                {{scope.row.amt_bgn | handleTH}}
              </template>
            </el-table-column>
            <el-table-column
              label="收入"
              align="center"
              sortable
              prop="amt_add"
            >
              <template slot-scope="scope">
                {{scope.row.amt_add | handleTH}}
              </template>
            </el-table-column>
            <el-table-column
              label="支出"
              align="center"
              sortable
              prop="amt_sub"
            >
              <template slot-scope="scope">
                {{scope.row.amt_sub | handleTH}}
              </template>
            </el-table-column>
            <el-table-column
              label="余额"
              align="center"
              sortable
              prop="amt_end"
            >
              <template slot-scope="scope">
                {{scope.row.amt_end | handleTH}}
              </template>
            </el-table-column>
            <el-table-column
              label="上年充值金额合计"
              align="center"
              sortable
              prop="amt_topup_last_year"
            >
              <template slot-scope="scope">
                {{scope.row.amt_topup_last_year | handleTH}}
              </template>
            </el-table-column>
            <el-table-column
              label="距上次充值天数"
              align="center"
              sortable
              prop="days_last"
            ></el-table-column>
          </el-table>
          <div style="text-align: right; padding-top: 20px">
                <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 30, 40, 50, 75, 100]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
        </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      size="20%"
      :with-header="false">
      <div class="rightTop">
        <div>
          <div>
            成交时间
          </div>
          <div>{{right_zhanshi.chengjiao_sj}}</div>
        </div>
        <div>
          <div>
            成交金额(元)
          </div>
          <div>{{jine_zhuanhua(right_zhanshi.chengjiao_jine)}}</div>
        </div>
        <div>
          <div>
            退回次数
          </div>
          <div>{{right_zhanshi.tuihui_cishu}}</div>
        </div>
        <div>
          <div>状态</div>
          <div>{{right_zhanshi.zhuangtai}}</div>
        </div>
        <div>
          <img src="../assets/me/eye.png" alt="" style="height: 25px;" @click="dakai_danju(xiangxi_xinxi)">
        </div>
      </div>
      <div style="display: flex;jusitify-content: center;">
      <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
            <!-- {{idx+1}}、 -->
        <p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
          <transition name="el-zoom-in-top">
            <div v-show="xiangqing_zhankai" class="transition-box">
              <!-- {{xiangxi_xinxi}} -->
              <p v-if="item.cust_name!==undefined&&item.cat_name==null">客户名称：{{item.cust_name}}</p>
              <p v-if="item.file_no!==undefined&&item.cat_name==null">归档号：{{item.file_no}}</p>
              <p v-if='item.staff_name'>负责人：{{item.staff_name}}</p>
              <p v-if="item.dept_name">部门：{{item.dept_name}}</p>
              <div>
                <ul>
                  <li v-for='(i,index) in item.contract_detail' :key="index">
                      <p>决策人({{index+1}})</p>
                      <p v-if="i.name!==undefined">姓名：{{i.name}}</p>
                      <p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
                      <p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
                      <p v-if="i.post!==undefined">职务：{{i.post}}</p>
                      <p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
                      <p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
                      <p v-if="i.birthday!==undefined&&i.birthady!=undefined">生日：{{i.birthday}}</p>
                      <p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
                      <p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
                      <p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
                      <p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
                      <p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
                  </li>
                </ul>
                <p v-if="item.province!==undefined&&item.cat_name!==null">省份：{{item.province}}</p>
                <p v-if="item.city!==undefined&&item.cat_name!==null">城市：{{item.city}}</p>
                <p v-if="item.industry!==undefined&&item.cat_name!==null">行业：{{item.industry}}</p>
                <p v-if="item.source!==undefined&&item.cat_name!==null">客户来源：{{item.source}}</p>
                <p v-if="item.level!==undefined&&item.cat_name!==null">客户等级：{{item.level}}</p>
                <p v-if="item.cat_name!==undefined&&item.cat_name!==null">客户标签：{{item.cat_name}}</p>
                <p v-if="item.biz_addr!==undefined&&item.cat_name!==null">经营地址：{{item.biz_addr}}</p>
                <p v-if="item.rel_p_com!==undefined&&item.cat_name!==null">母公司名称：{{item.rel_p_com}}</p>
                <p v-if="item.register_addr!==undefined&&item.cat_name!==null">注册地址：{{item.register_addr}}</p>
                <p v-if="item.legal_name!==undefined&&item.cat_name!==null">法定代表人：{{item.legal_name}}</p>
                <p v-if="item.uscc!=''&&item.cat_name!==null">营业执照号：{{item.uscc}}</p>
                <p v-if="item.register_capital!==undefined&&item.cat_name!==null">注册资本：{{item.register_capital}}</p>
                <p v-if="item.register_date!==undefined&&item.cat_name!==null">成立日期：{{item.register_date}}</p>
                <p v-if="item.biz_term!==undefined&&item.cat_name!==null">营业期限：{{item.biz_term}}</p>
                <p v-if="item.biz_scope!==undefined&&item.cat_name!==null">营业范围：{{item.biz_scope}}</p>
                <p v-if="item.biz_lic_pic!==undefined&&item.cat_name!==null">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
              </div>
              <ul>
                <li v-for="(i,index) in item.zidingyi" :key="index">
          <!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
                <div v-if="i.type=='ddattachment'">
                  <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
                  <ul>
                    <li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
                      <el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
                        <p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
                          <i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
                          {{it.name}}
                        </p>
                      </el-tooltip>
                    </li>
                  </ul>
                </div>
          <!-- 关联审批单 -->
                <div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                  <p>{{i.name}}：</p>
                  <ul>
                    <li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
                      <p class="guanlian_kuang">
                        <i class="el-icon-connection" style="margin-right:0.1rem;"></i>
                        {{it.flow_desc}}:{{it.start_time}}
                      </p>
                    </li>
                  </ul>
            <!-- <BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing> -->
          </div>
          <!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
            {{i.name}}：
            <v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
          </div> -->
          <div v-if="i.type=='tablefield'">
            <div v-for="(it,inde) in i.mingxi_list" :key="inde">
              {{i.name+'('+(inde+1)+')'}}：
              <div v-for="(ite,ind) in it.mingxi_list" :key="ind">
                <p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
                <p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
                  {{ite.name}}：{{ite.value}}
                </p>
                <div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
                  <p>{{ite.name}}：{{ite.value}}</p>
                  <p>产品编号：{{ite.liebiao[0].prod_code}}</p>
                  <p>产品类型：{{ite.liebiao[0].cat_name}}</p>
                  <p>规格：{{ite.liebiao[0].spec}}</p>
                  <p>型号：{{ite.liebiao[0].type}}</p>
                  <p>单位：{{ite.liebiao[0].unit}}</p>
                </div>
              </div>
            </div>
          </div>
          <p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
          <p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
            {{i.name}}：{{i.value}}
          </p>
          <div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
            <p>{{i.name}}：{{i.value}}</p>
            <p>产品编号：{{i.liebiao[0].prod_code}}</p>
            <p>产品类型：{{i.liebiao[0].cat_name}}</p>
            <p>规格：{{i.liebiao[0].spec}}</p>
            <p>型号：{{i.liebiao[0].type}}</p>
            <p>单位：{{i.liebiao[0].unit}}</p>
          </div>
        </li>
      </ul>
                </div>
            </transition>
        </div>
        
      </div>
      <div style="display: flex;jusitify-content: center;">
        <div style="margin-top:0.2rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_zhankai=!xiangqing_kehu_zhankai">客户操作日志<i :class="xiangqing_kehu_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_zhankai" class="transition-box">
                            <ul>
                                <li v-for="(i,index) in zhanshi_lishi" :key="index">
                                    <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.staff_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}从{{i.dept_name}}冷冻仓激活到公海池；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}接收{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}拒绝{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='6'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入公海池；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='8'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入已分配；</p>
                                    <p v-if="i.type=='9'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}修改；</p>
                                    <p v-if="i.type=='10'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入</p>
                                    <p v-if="i.type=='11'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动退回</p>
                                    <p v-if="i.type=='12'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动从公海池退回冷冻仓</p>
                                    <!-- <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已接收；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，{{i.accept_time}}{{i.staff_name}}已拒绝；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}，待{{i.staff_name}}接受；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id=='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入公海池；</p>
                                    <p v-if="i.type=='6'&&i.operate_user_id!='sys'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}{{i.dept_name}}{{i.operate_user_name}}被系统录入公海池；</p> -->
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
      </div>
      <div style="display: flex;jusitify-content: center;">
        <div style="margin-top:0.2rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" class="transition-box">
                            <p class="transition-box_tit">
                                选择归档号：
                            </p>
                            <p class='transition-box_tit_file'>
                                <span v-for="(i,index) in fujian_list" @click="dianji_guidang_hao(index)" :key="index"><img :src="zhanshi_guidang_hao==index?require('../assets/me/xuan_zi.png'):require('../assets/me/weixuan_zi.png')" alt="">{{i.file_no}}</span>
                            </p>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].con_annex!=undefined">
                                <p class="fujian_con_tit">合同附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].con_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].inv_annex!=undefined">
                                <p class="fujian_con_tit">发票附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].rec_annex!=undefined">
                                <p class="fujian_con_tit">回款附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].rec_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined">
                                <p class="fujian_con_tit">交付附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].deliver_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined||fujian_list[zhanshi_guidang_hao].con_annex!=undefined||fujian_list[zhanshi_guidang_hao].rec_annex!=undefined||fujian_list[zhanshi_guidang_hao].inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../assets/ok_blue.png'):require('../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[zhanshi_guidang_hao].deliver_annex==undefined&&fujian_list[zhanshi_guidang_hao].con_annex==undefined&&fujian_list[zhanshi_guidang_hao].rec_annex==undefined&&fujian_list[zhanshi_guidang_hao].inv_annex==undefined)">该归档号下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
      </div>
    </el-drawer>
    <yanjing ref="eye"></yanjing>
  </div>
</template>
<script>
import {
  query_mc_trans_list_gather,
  query_mc_trans_gather_data,
  query_mc_trans_list_detail,

} from "@/api/vip";
import { query_flow_info_list } from '@/api/api'
import {
   query_flow_info_all,
   query_form_list_file_nos,
   query_customer_detail,
   customer_annex_download,
   query_customer_mng_log_list,
   query_customer_else_info
} from "@/api/api";
import yanjing from '../page/pagea/yanjing'
export default {
  name: "vip",
  filters: {
    handleTH(val) {
      return val.replace(/\B(?=(\d{3})+$)/g,',')
    }
  },
  components: { yanjing },
  data() {
    return {
      height: null,
      drawer: false,
      datau: null,
      heightSmall: null,
      topData: {
        cust_num_total: "0",
        amt_add_sum: "0",
        amt_sub_sum: "0",
        amt_end_sum: "0",
      },
      restaurants: [],
      xiangqing_kehu_zhankai: false,
      zhanshi_lishi: [],
      TodayTime: null,
      fujian_list: [],
      zhanshi_guidang_hao: '0',
      dateRange: null,
      reachContent: null,
      dialogVisible: false,
      tablCeData: [],
      loading: false,
      activeNames: null,
      direction: "rtl",
      content: null,
      searchVal: "",
      multipleSelection: [],
      xiangxi_xinxi: [],
      xiangqing_zhankai: false,
      xiangqing_kehu_fujian: false,
      radio: "",
      right_zhanshi: {
        chengjiao_sj: '',
        chengjiao_jine: '',
        tuihui_cishu: '',
        zhuangtai: ''
      },
      company: '万元',
      total:100,//共计100页
      currentPage4: 1,//当前页数
      pagesize:10,//每页多少条
       // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/'
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
       //url:'http://192.168.3.5:8080/hwb_client/V1.0.0/',
      group: true,
    };
  },
  created() {
    this.height = window.innerHeight - 37;
    this.heightSmall = this.height - 110;
    this.query_mc_trans_list_gather();


  },
  computed: {
    //  newlistdata() {
    //   let newlistdata = [];
    //   this.tablCeData.map(item => {
    //     if (item.cust_name.search(this.searchVal) != -1) {
    //       newlistdata.push(item);
    //     }
    //   });
    //   return newlistdata;
    // }
  },
  methods: {
    dakai_danju (i) {
      this.$refs.eye.dianji_dakai(i[0])
    },
    dianji_guidang_hao (index) {
      this.zhanshi_guidang_hao = index
      const item = this.fujian_list[index]
      if (item.con_annex != undefined) {
        item.con_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.inv_annex != undefined) {
        item.inv_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.rec_annex != undefined) {
        item.rec_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
      if (item.deliver_annex != undefined) {
        item.deliver_annex.forEach(ite => {
          this.$set(ite, 'zhi', false)
        })
      }
    },
    dianji_xinzeng () {

        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: 'AY3cli7BAb'
          }
        }).then(res => {
          const date = res.data.body.form_str
            console.log(date)
            const data = []
            for (let a = 0; a < date.length; a++) {
              if (date[a].defaultImportant != undefined) {
                date[a].defaultImportant = date[a].defaultImportant != 'false'
              }
              if (date[a].defaultPrint != undefined) {
                date[a].defaultPrint = date[a].defaultPrint != 'false'
              }
              if (date[a].defaultOptions != undefined) {
                date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
              }
              if (date[a].gongshi != undefined) {
                date[a].gongshi = JSON.parse(date[a].gongshi)
              }
              if (date[a].supportSetting != undefined) {
                date[a].supportSetting = JSON.parse(date[a].supportSetting)
              }
              if (date[a].components != undefined) {
                date[a].components = JSON.parse(date[a].components)
                for (let b = 0; b < date[a].components.length; b++) {
                  if (date[a].components[b].defaultImportant != undefined) {
                    if (typeof (date[a].components[b].defaultImportant) === 'string') {
                      date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                    }
                  }
                  if (date[a].components[b].defaultPrint != undefined) {
                    if (typeof (date[a].components[b].defaultPrint) === 'string') {
                      date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                    }
                  }
                  // typeof(str)=='string'
                  if (date[a].components[b].defaultOptions != undefined) {
                    if (typeof (date[a].components[b].defaultOptions) === 'string') {
                      date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                    }
                  }
                  if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                    if (typeof (date[a].components[b].gongshi) === 'string') {
                      console.log(date[a].components[b].gongshi)
                      date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                    }
                  }
                  if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                    if (typeof (date[a].components[b].supportSetting) === 'string') {
                      console.log(date[a].components[b].supportSetting)
                      date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                    }
                  }
                }
              }
              if (date[a].componentName != 'contacts' && date[a].defaultLable != '负责人') {
                data.push(date[a])
              }
            }
            const obj = {
              flow_id: 'AY3cli7BAb',
	            flow_name: '会员充值单'
            }
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '公海池', '暂时')
            this.$router.push('/usetable')
        })
    },
    handeDownload() {
      if(this.dateRange) {
        const url = this.url +
          "export_gather_mc_acct?ent_id=" +
          this.$ent_id() +
          "&user_id=" +
          this.$jichuxinxi().user_id +
          "&date_start=" +
          this.dateRange[0] +
          "&date_end=" +
          this.dateRange[1]
        const data = [url];
        //console.log(data)
        for (let i = 0; i < data.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = data[i];
          document.body.appendChild(iframe);
          setTimeout((res) => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      } else {
        const url = this.url +
          "export_gather_mc_acct?ent_id=" +
          this.$ent_id() +
          "&user_id=" +
          this.$jichuxinxi().user_id
        const data = [url];
        //console.log(data)
        for (let i = 0; i < data.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = data[i];
          document.body.appendChild(iframe);
          setTimeout((res) => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      }
    },
    async query_mc_trans_list_gather(queryString) {
      try {
        this.loading = true;
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        let formatnowdate = y + "-" +  this.$func.pa(m) + "-" + this.$func.pa(d);
        date.setMonth(date.getMonth() - 1);
        var ago = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000); //30天前
        let day = ("0" + (ago.getDate() - 1)).slice(-2);
        let month = ("0" + (ago.getMonth() + 2)).slice(-2);
        let oneMonAgo = ago.getFullYear() + "-" + this.$func.pa(month) + "-" + this.$func.pa(day);
        if (this.dateRange) {
          oneMonAgo = this.dateRange[0];
          formatnowdate = this.dateRange[1];
        }
        console.log(oneMonAgo,formatnowdate,'日期好像有问题')
        let param = {
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            search_str: queryString,
            page_size: JSON.stringify(this.pagesize), //每页条数
            page_index: JSON.stringify(this.currentPage4),  //当前页数
            date_start: oneMonAgo,
            date_end: formatnowdate,
          },
        };
        const res = await query_mc_trans_list_gather(param);
        if (res.data.code === "200") {
          this.tablCeData = []
          const response = JSON.parse(res.data.body.data);
          this.loading = false;
          this.tablCeData = response.list;
          this.total = response.total;
          this.tablCeData.forEach(item => {
            if(item.amt_bgn.length > 5) {
              item.amt_bgn = (item.amt_bgn / 10000).toFixed(2)
            } else {
              item.amt_bgn = item.amt_bgn
            }
            if(item.amt_add.length > 5) {
              item.amt_add = (item.amt_add / 10000).toFixed(2)
            } else {
              item.amt_add = item.amt_add
            }
            if(item.amt_sub.length > 5) {
              item.amt_sub = (item.amt_sub / 10000).toFixed(2)
            } else {
              item.amt_sub = item.amt_sub
            }
            if(item.amt_end.length > 5) {
              item.amt_end = (item.amt_end / 10000).toFixed(2)
            } else {
              item.amt_end = item.amt_end
            }
            if(item.amt_topup_last_year.length > 5) {
              item.amt_topup_last_year = (item.amt_topup_last_year / 10000).toFixed(2)
            } else {
              item.amt_topup_last_year = item.amt_topup_last_year
            }
          })
        }
        this.query_mc_trans_gather_data(oneMonAgo,formatnowdate);
      } catch {
        this.loading = false;
      }

    },
    async query_mc_trans_gather_data(date_start,date_end) {
        let data = {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          date_start,
          date_end,
        };
        console.log(data,'这是啥啊')
        data = await query_mc_trans_gather_data({ data });
       // console.log(data,'2323423432432这里获取')
        if (data.data.code === "200") {
          this.topData = JSON.parse(data.data.body.data);
          //console.log(this.topData, 13131313)
          this.topData.amt_add_sum = (this.topData.amt_add_sum / 10000).toFixed(2)
          this.topData.amt_end_sum = (this.topData.amt_end_sum / 10000).toFixed(2)
          this.topData.amt_sub_sum = (this.topData.amt_sub_sum / 10000).toFixed(2)
          let num = Math.floor(this.topData.cust_unit_price)
          this.topData.cust_unit_price = (num / 10000).toFixed(2)
        } else {
          this.$message({
            message: "获取信息失败",
            type: "warning",
          });
      }
    },
    handleSelect() {},
    querySearch(queryString, cb) {
      //       console.log(queryString, cb)
      //
      this.query_mc_trans_list_gather(queryString);

      let results = this.tablCeData;
      this.restaurants = this.tablCeData;
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleindexChange() {
      console.log(`每ye`);
    },
    selectRow(selection, row) {
      console.log(this.$refs.table);
      this.multipleSelection = this.$refs.table.selection;
      if (selection.length > 1) {
        let del_row = selection.shift();
        console.log(del_row);
        this.$refs.table.toggleRowSelection(del_row, false);
        return false;
      } else if (selection.length === 0) {
        this.$message.info("请选择一项");
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      // if(val.length < 1 || val.length > 1) {
      //   val.slice(0, 0)
      //   return false
      // }
    },
    jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return this.$qianwei((a / 100000000).toFixed(2))
      } else if (a >= 10000 && a < 100000000) {
        return this.$qianwei((a / 10000).toFixed(2))
      } else if (a < 10000) {
        return this.$qianwei(a.toFixed(2))
      }
    },
    // 金额文字转化
    wenzi_jine_zhuanhua (num) {
      const a = Number(num)
      if (a >= 100000000) {
        return '亿'
      } else if (a >= 10000 && a < 100000000) {
        return '万元'
      } else if (a < 10000) {
        return '元'
      }
    },
    handleNeedClick (val) {
      console.log(val, 123123123)
      query_customer_detail({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          file_no: val.file_no,
          dept_id: val.dept_id
        }
      }).then(rds => {
        console.log(rds, 213232123)
        if (rds.data.code == 200) {
          const xiangqing = JSON.parse(rds.data.body.data)
          console.log(xiangqing, 'asdsad')
          this.xiangxi_xinxi = []
          this.xiangxi_xinxi.push(xiangqing)
          console.log(this.xiangxi_xinxi, 8934722)
          this.xiangxi_xinxi.forEach(item => {
            this.$set(item, 'zidingyi', item.extra != undefined ? JSON.parse(item.extra) : [])
            this.$set(item, 'dept_name', val.dept_name != undefined ? val.dept_name : '')
          })
          // 附件下载接口
          customer_annex_download({
            data: {
              ent_id: this.$ent_id(),
              cust_id: val.cust_id,
              // dept_id:val.dept_id,
              file_no: xiangqing.file_no,
              staff_id: val.staff_id != undefined ? val.staff_id : null
            }
          }).then(fujian => {
            console.log(fujian)
            if (fujian.data.code == 200) {
              const date = JSON.parse(fujian.data.body.data)
              console.log(date)
              date.forEach(item => {
                if (item.con_annex != undefined) {
                  item.con_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.inv_annex != undefined) {
                  item.inv_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.rec_annex != undefined) {
                  item.rec_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
                if (item.deliver_annex != undefined) {
                  item.deliver_annex.forEach(ite => {
                    this.$set(ite, 'zhi', false)
                  })
                }
              })
              for (let i = 0; i < date.length; i++) {
                if (date[i].file_no == undefined) {
                  date.splice(i, 1)
                  i = i - 1
                }
              }
              setTimeout(() => {
                this.fujian_list = date
              }, 0)
            } else if (fujian.data.code == 500) {}
          })
        } else if (rds.data.code == 500) {}
      })
      query_customer_mng_log_list({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.zhanshi_lishi = date != undefined && date != null && date.length != 0 ? date : []

          // let list=[]
          // date.forEach(ite=>{
          //     // if(ite.type!=4&&ite.type!=5){
          //         list.push(ite)
          //     // }
          // })
          // query_cust_allot_log_list({data:{
          //     ent_id:this.$ent_id(),
          //     // allot_user_id:this.$jichuxinxi().user_id,
          //     cust_id:val.cust_id
          // }}).then(ras=>{
          //     console.log(ras)
          //     if(ras.data.code==200){
          //         let data=JSON.parse(ras.data.body.data)
          //         console.log(data)
          //         data.forEach(item=>{
          //             if(item.accept_status==0){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',7)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==1){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',4)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }else if(item.accept_status==2){
          //                 this.$set(item,'operate_time',item.allot_time)
          //                 this.$set(item,'type',5)
          //                 this.$set(item,'operate_user_name',item.allot_user_name)
          //                 list.push(item)
          //             }
          //         })
          //         list=this.$func.ReverseRankingDate(list,'operate_time')
          //         console.log(list)
          //         this.zhanshi_lishi=list
          //     }else if(res.data.code==500){}
          // })
        } else if (res.data.code == 500) {}
      })
      query_customer_else_info({
        data: {
          ent_id: this.$ent_id(),
          cust_id: val.cust_id,
          type: '2',
          dept_id: val.dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          console.log(this.lv1)
          console.log(this.lv2)
          if (date.allot_type == 1) {
            this.right_zhanshi.zhuangtai = '新增'
          } else if (date.allot_type == 2) {
            this.right_zhanshi.zhuangtai = '系统分配'
          } else if (date.allot_type == 3) {
            this.right_zhanshi.zhuangtai = '指派'
          }
          console.log(date)
          this.right_zhanshi.chengjiao_sj = date.date_trans.length != 0 ? date.date_trans : '—'
          this.right_zhanshi.chengjiao_jine = Number(date.amt_trans).toFixed(2)
          this.right_zhanshi.tuihui_cishu = date.back_num
        } else if (res.data.code == 500) {}
      })
      this.drawer = true
    },
   handleSizeChange(val) {
       this.pagesize = val
        this.query_mc_trans_list_gather()
          //
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.query_mc_trans_list_gather()
    },
    lookDetail() {
      // this.$router.push('/vipDetailTable')
      const data = this.multipleSelection[0].cust_id
      const date = this.multipleSelection[0].cust_name
      sessionStorage.setItem("kuhe_id",JSON.stringify(data));
      this.$router.push({
        name: "vipDetailTable",
        params: { id: data, name: date },
      });
    },
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>
<style scoped lang="scss">
.vip {
  width: 100%;
  overflow: hidden;
  .vip-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 78px;
    background: #fff;
  }
  .vip-statistics {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 4px;
    padding-top: 78px;
    width: 100%;
    text-align: center;
    .red-num {
      color: #e31e1e;
    }
  }
  .vip-condition {
    padding-top: 18px;
    padding-left: 33px;
    display: flex;
    justify-content: space-between;
    .vip-all {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 4px;
      .vip-buy {
        width: 48px !important;
        color: #fff;
        height: 34px;
        background: #9a86db;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        font-family: Source Han Sans CN;
        padding-bottom: 12px;
      }
      .el-range-editor.el-input__inner {
        width: 230px;
        padding-left: 10px;
        height: 34px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 0;
      }
    }
    .vip-right {
      .lookDetail {
        width: 100px;
        height: 34px;
        background: #9a86db;
        border-radius: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 12px;
      }
      .inputOp {
        width: 250px;
        .el-input__inner {
          border: 2px solid #cccccc !important;
          border-radius: 17px !important;
        }
      }
      .vip-exprot {
        width: 70px;
        height: 34px;
        border: 2px solid #cccccc;
        border-radius: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        padding-top: 6px !important;
      }
      .vip-yuan {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 4px;
        padding-right: 20px;
      }
    }
  }
  .vip-table {
    padding-top: 18px;
    padding-right: 20px;
  }
}
/deep/ .el-date-editor .el-range__icon {
  padding-left: 25px !important;
}
.rightTop{
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  div{
    text-align: center;
  }
}
.youce_xiangxi_xinxi{
  margin-top: 20px;
  width: 90%;
  background: #f4f7fd;
  padding: 0 32px;
  color: #1a2533;
  line-height: 20px;
  font-size: 14px;
  padding-bottom: 10px;
}
.xiangxi_tit{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
@import '../style/salesman.scss'
</style>
